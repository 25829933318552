@font-face {
    font-family: base;
    src: url("font.otf") format("opentype");
}
@font-face {
    font-family: base-bold;
    src: url("font-bold.ttf");
}

html{
    height: 100%;
}

body {
    width: 84%;
    margin: 0 auto;
    font-family: base, sans-serif;
    background: lightgray;
    min-height: 100%;
    max-width: 1920px;
}

.header{
    background-color: white;
    min-height: 55px;
    margin-top: 0 !important;
    position: relative;
}

.logo{
    position: absolute;
    left: 0;
}

.logo img{
    max-width: 130px;
    max-height: 130px;
}

.tituloheader{
    position: absolute;
    left: 6vw;
    top: -4px
}

.idiomas{
    position: absolute;
    top: 0;
    right: 4vw;
}

h3{
    margin-top: 0 !important;
}

.drop-component {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    background-image: url("img/madeira.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    padding-right: 3vw;
    padding-top: 6vh;
    margin-left: 0 !important;
}

.destinos {
    width: 60%;
}

.fontes {
    width: 30%;
    margin-left: 0 !important;
    font-size: 13pt;
}

.linha {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    /*padding: 0 -1em;*/
}

.box-cor, .box-geral {
    text-shadow: 0 0 2px black;
    color: white;
    text-align: center;
    font-family: base-bold, sans-serif;
    font-size: 14pt;
    padding-top: 1em;
}

.box-cor {
    width: 50%;
    min-height: 16em;
    margin: 0 auto;
    background-image: url("/src/img/fundo.png");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    border-bottom: 0.3em solid;
}

.box-geral {
    background-color: #ffffff;
    border-top: 0.6em solid #00a99d;
    border-right: 0.6em solid #00a99d;
    border-bottom: 0.6em solid #00a99d;
    border-radius: 0 25px 25px 0;
    position: relative;
    padding-bottom: 1.1em;
    min-height: 24vh !important;
}


.infos {
    background-color: #ffffff;
    border-radius: 0 25px 25px 0;
    border-top: 1px solid #00a99d;
    border-right: 1px solid #00a99d;
    border-bottom: 1px solid #00a99d;
    position: relative;
    padding-bottom: 1.1em;
    margin-top: 1em;
    padding-top: 1em;
    padding-left: 10px;
}

.box-geral .passo {
    position: absolute;
    right: 10px;
    bottom: 1px;
    color: #615b58;
    text-shadow: none;
}

.box-cor.a {
    background-color: #253355;
    border-color: #000b2d;
    margin-bottom: 0;
}

.box-cor.b {
    background-image: url("/src/img/fundo-escuro.png");
    background-color: #eceeee;
    border-color: #a6aead;
}

.box-cor.l {
    background-color: #ED7125;
    border-color: #c54900;
}

.box-cor.m {
    background-color: #884C26;
    border-color: #602400;
}

.box-cor.v {
    background-color: #557115;
    border-color: #2d4900;
    margin-bottom: 0;
}

.box-imagens {
    width: 30%;
}

.item-frase {
    display: inline-block;
    overflow: hidden;
    color: white;
    background-color: #808080;
    margin: 0.7em;
    padding: 0.3em 0.5em;
    border-radius: 2px;
    cursor: grab;
}

.item-frase.correto {
    background-color: #fee7c7;
    cursor: default;
    color: black;
}

.item-frase.correto.bt{
    cursor: default;
}

.item-frase.errado {
    background-color: #c24242;
}

h1 {
    margin: 0;
    text-align: center;
    font-size: 1.2em;
}

h1 .item-frase {
    margin-top: 0;
}


.figura {
    max-width: 130px;
    max-height: 130px;
}

.instrucoes {
    font-size: 1em;
}

.instrucoes h1 {
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
}

.instrucoes .item-frase {
    margin: 0;
    cursor: default;
}

ol {
    margin-top: 0.5em;
    margin-right: 1em;
    text-align: justify;
}

button {
    background: no-repeat;
    border: none;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.icone-locale {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
    margin: 0 5px;
    border-radius: 3px;
}

.icone-locale.ativo {
    border: 3px solid red;
}

.icone-locale img {
    display: block;
    max-height: 30px;
}

.caixa-cores{
    position: relative;
    width: 70%;
    margin-left: 4em;
    font-size: 13pt;
}

.caixa-cores .caixa{
    transition: all 0.3s linear;
}

.caixa.b{
    position: absolute;
    top:0;
    left: 0;
    width: 49%;
    height: 48vh;
    background-color: #c3c4c5;
    border-color: #a6aead;
    background-position: bottom left;
    background-size: contain;
    background-repeat: no-repeat;
    border-top-left-radius: 20px;
    min-height: 430px !important;
}

.caixa.l{
    position: absolute;
    top:0;
    left: 49%;
    width: 49%;
    height: 48vh;
    background-color: #d17c19;
    border-color: #c54900;
    border-top-right-radius: 20px;
    min-height: 430px !important;
}

.caixa.v{
    position: absolute;
    top: 48vh;
    left: 0;
    width: 49%;
    height: 48vh;
    background-color: #76a735;
    border-color: #2d4900;
    border-bottom-left-radius: 20px;
    min-height: 430px !important;
}

.caixa.a{
    position: absolute;
    top: 48vh;
    left: 49%;
    width: 49%;
    height: 48vh;
    background-color: #17639f;
    border-color: #000b2d;
    border-bottom-right-radius: 20px;
    min-height: 430px !important;
}

.caixa.m{
    position: absolute;
    top:calc(30vh - 10px);
    left: calc(29% - 10px);
    width: 40%;
    height: 40vh;
    background-color: #60564b;
    border: 10px solid white;
    border-radius: 20px;
    min-height: 320px !important;
}

.caixa.b{
    border-right: 10px solid white;
    border-bottom: 10px solid white;
}

.caixa.v{
    border-right: 10px solid white;
    border-top: 10px solid white;
}

.caixa.l{
    border-left: 10px solid white;
    border-bottom: 10px solid white;
}

.caixa.a{
    border-left: 10px solid white;
    border-top: 10px solid white;
}

.caixa > .container{
    position: relative;
}

.titulo{
    position: absolute;
    margin-top: 20px;
}

.titulo.b{
    top:0;
    left: 0;
}

.titulo.v{
    top: 39vh;
    left: 0;
}

.titulo.l{
    top:0;
    right: 0;
}

.titulo.a{
    top: 39vh;
    right: 0;
}

.titulo.m{
    top: 0;
    width: 100%;
}

.imagem{
    position: absolute;
}

.imagem.b{
    top:0;
    right: 0;
}

.imagem.m{
    top: 6vh;
    right: 0;
}

.imagem.l{
    top:0;
    left:0
}

.imagem.v{
    top: 28vh;
    right: 0;
}

.imagem.a{
    top: 28vh;
    left: 0;
}

.itens.b, .itens.v{
    position: relative;
    width: 54%;
}

.itens.l, .itens.a{
    position: relative;
    width: 56%;
    margin-left: 44%;
}


.itens.b .b0{
    position: absolute;
    top: 7vh;
    left: 0.2vw;
}

.itens.b .b1{
    position: absolute;
    top: 12vh;
    left: 0.2vw;
}

.itens.b .b2{
    position: absolute;
    top: 17vh;
    left: 0.2vw;
}

.itens.b .b3{
    position: absolute;
    top: 22vh;
    left: 0.2vw;
}

.itens.b .b4{
    position: absolute;
    top: 32vh;
    left: 0.2vw;
}

.itens.v .v0 {
    position: absolute;
    top: 35vh;
    left: 0.2vw;
}

.itens.v .v1{
    position: absolute;
    top: 30vh;
    left: 0.2vw;
}

.itens.v .v2{
    position: absolute;
    top: 25vh;
    left: 0.2vw;
}

.itens.v .v3{
    position: absolute;
    top: 18vh;
    left: 0.2vw;
}

.itens.v .v4{
    position: absolute;
    top: 13vh;
    left: 0.2vw;
}

.itens.l .l0{
    position: absolute;
    top: 7vh;
    right: 4px;
}

.itens.l .l1{
    position: absolute;
    top: 12vh;
    right: 4px;
}

.itens.l .l2{
    position: absolute;
    top: 17vh;
    right: 4px;
}

.itens.l .l3{
    position: absolute;
    top: 22vh;
    right: 4px;
}

.itens.l .l4{
    position: absolute;
    top: 29.5vh;
    right: 4px;
}

.itens.a .a0 {
    position: absolute;
    top: 35vh;
    right: 4px;
}

.itens.a .a1{
    position: absolute;
    top: 30vh;
    right: 4px;
}

.itens.a .a2{
    position: absolute;
    top: 25vh;
    right: 4px;
}

.itens.a .a3{
    position: absolute;
    top: 18vh;
    right: 4px;
}

.itens.a .a4{
    position: absolute;
    top: 13vh;
    right: 4px;
}

.itens.m{
    position: relative;
    width: 50%;
    text-align: left;
}

.itens.m .m0 {
    position: absolute;
    top: 6vh;
}

.itens.m .m1{
    position: absolute;
    top: 11vh;
}

.itens.m .m2{
    position: absolute;
    top: 16vh;
}

.itens.m .m3{
    position: absolute;
    top: 23.5vh;
    min-width: 360px;
}

.itens.m .m4{
    position: absolute;
    top: 31vh;
}



.itens.b .errado{
    position: absolute;
    top:37vh;
    left: 0.2vw;
}

.itens.l .errado{
    position: absolute;
    top:37vh;
    right: 0.2vw;
}

.itens.v .errado{
    position: absolute;
    top: 0;
    left: 0.2vw;
}

.itens.a .errado{
    position: absolute;
    top: 0;
    right: 0.2vw;
}

.itens.m .errado{
    position: absolute;
    top: 31.5vh;
    width: max-content;
    max-width: 300px;

}


@media screen and (max-width: 1366px) {
    body {
        width: 100% !important;
        margin: 0 auto;
        font-family: base, sans-serif;
        background: lightgray;
        min-height: 100%;
    }

    .tituloheader{
        left: 15vw;
        top: -4px
    }

    .fontes{
        width: 35.5% !important;
    }
    .idiomas{
        position: absolute;
        top: -10px;
    }

    .drop-component{
        padding-top: 10vh;
        padding-right: 1vw !important;
    }

    .box-geral, .infos{
        font-size: 13pt;
    }

    .infos{
        padding-left: 4px !important;
    }

    .caixa-cores{
        margin-left: 30px !important;
        margin-right: 0 !important;
    }

    .itens.b, .itens.v{
        position: relative;
        width: 58%;
    }

    .itens.l, .itens.a{
        position: relative;
        width: 59%;
        margin-left: 41%;
    }

    .itens.b .b0 {
        position: absolute;
        top: 7vh;
        left: 0.2vw;
    }

    .itens.b .b1{
        position: absolute;
        top: 12.5vh;
        left: 0.2vw;
    }

    .itens.b .b2{
        position: absolute;
        top: 18vh;
        left: 0.2vw;
    }

    .itens.b .b3{
        position: absolute;
        top: 23.5vh;
        left: 0.2vw;
    }

    .itens.b .b4{
        position: absolute;
        top: 35vh;
        left: 0.2vw;
    }

    .itens.v .v0 {
        position: absolute;
        top: 33.5vh;
        left: 0.2vw;
    }

    .itens.v .v1{
        position: absolute;
        top: 28.5vh;
        left: 0.2vw;
    }

    .itens.v .v2{
        position: absolute;
        top: 23vh;
        left: 0.2vw;
    }

    .itens.v .v3{
        position: absolute;
        top: 14.5vh;
        left: 0.2vw;
    }

    .itens.v .v4{
        position: absolute;
        top: 9vh;
        left: 0.2vw;
    }

    .itens.l .l0{
        position: absolute;
        top: 7vh;
        right: 0.2vw;
    }

    .itens.l .l1{
        position: absolute;
        top: 12.5vh;
        right: 0.2vw;
    }

    .itens.l .l2{
        position: absolute;
        top: 18vh;
        right: 0.2vw;
    }

    .itens.l .l3{
        position: absolute;
        top: 23.5vh;
        right: 0.2vw;
    }

    .itens.l .l4{
        position: absolute;
        top: 32vh;
        right: 0.2vw;
    }

    .itens.a .a0 {
        position: absolute;
        top: 33.5vh;
        right: 0.2vw;
    }

    .itens.a .a1{
        position: absolute;
        top: 28vh;
        right: 0.2vw;
    }

    .itens.a .a2{
        position: absolute;
        top: 22.5vh;
        right: 0.2vw;
    }

    .itens.a .a3{
        position: absolute;
        top: 14vh;
        right: 0.2vw;
    }

    .itens.a .a4{
        position: absolute;
        top: 8.5vh;
        right: 0.2vw;
    }

    .itens.m .m0 {
        position: absolute;
        top: 6vh;
    }

    .itens.m{
        font-size: 13pt;
    }

    .itens.m .m1{
        position: absolute;
        top: 11vh;
    }

    .itens.m .m2{
        position: absolute;
        top: 16vh;
    }

    .itens.m .m3{
        position: absolute;
        top: 23.5vh;
        min-width: 300px;
    }

    .itens.m .m4{
        position: absolute;
        top: 31vh;
    }



    .imagem.b{
        top:0;
        right: 0;
    }

    .imagem.m{
        top: 7vh;
        right: 0;
    }

    .imagem.m img{
        width: 100px;
    }

    .imagem.l{
        top:0;
        left:0
    }

    .imagem.v{
        top: 25vh;
        right: 0;
    }

    .imagem.a{
        top: 25vh;
        left: 0;
    }



    .titulo.b{
        top:0;
        left: 0;
    }

    .titulo.v{
        top: 38vh;
        left: 0;
    }

    .titulo.l{
        top:0;
        right: 0;
    }

    .titulo.a{
        top: 38vh;
        right: 0;
    }

    .titulo.m{
        top: -1vh;
        width: 100%;
    }

    .final{
        font-size: 13pt;
    }

    .itens.b .errado{
        position: absolute;
        top:32vh;
        left: 0.2vw;
    }

    .itens.l .errado{
        position: absolute;
        top:35vh;
        right: 0.2vw;
    }

    .itens.v .errado{
        position: absolute;
        top: 0;
        left: 0.2vw;
    }

    .itens.a .errado{
        position: absolute;
        top: 0;
        right: 0.2vw;
    }

    .itens.m .errado{
        position: absolute;
        top: 31.5vh;
        width: max-content;
        max-width: 280px;
    }

    .caixa.b{
        position: absolute;
        top:0;
        left: 0;
        width: 49%;
        height: 44vh;
        background-color: #c3c4c5;
        background-position: bottom left;
        background-size: contain;
        background-repeat: no-repeat;
        border-top-left-radius: 20px;
        min-height: 360px !important;
    }

    .caixa.l{
        position: absolute;
        top:0;
        left: 49%;
        width: 49%;
        height: 44vh;
        background-color: #d17c19;
        border-top-right-radius: 20px;
        min-height: 360px !important;
    }

    .caixa.v{
        position: absolute;
        top: 44vh;
        left: 0;
        width: 49%;
        height: 44vh;
        background-color: #76a735;
        border-bottom-left-radius: 20px;
        min-height: 360px !important;
    }

    .caixa.a{
        position: absolute;
        top: 44vh;
        left: 49%;
        width: 49%;
        height: 44vh;
        background-color: #17639f;
        border-bottom-right-radius: 20px;
        min-height: 360px !important;
    }

    .caixa.m{
        position: absolute;
        top:calc(26vh - 10px);
        left: calc(29% - 10px);
        width: 40%;
        height: 38vh;
        background-color: #60564b;
        border: 10px solid white;
        border-radius: 20px;
    }

}

@media screen and (min-width: 1367px) and (max-width: 1442px){
    body {
        width: 100% !important;
        margin: 0 auto;
        font-family: base, sans-serif;
        background: lightgray;
        min-height: 100%;
    }

    .tituloheader{
        left: 14vw;
    }

    .idiomas{
        position: absolute;
        top: -10px;
    }

    .drop-component{
        padding-top: 8vh;
        padding-right: 1vw !important;
    }

    .box-geral, .infos{
        font-size: 13pt;
    }

    .caixa-cores{
        margin-left: 30px !important;
        margin-right: 0 !important;
    }

    .fontes{
        width: 34% !important;
    }

    .infos{
        padding-left: 2px !important;
    }

    .itens.b, .itens.v{
        position: relative;
        width: 58%;
    }

    .itens.l, .itens.a{
        position: relative;
        width: 59%;
        margin-left: 41%;
    }

    .itens.b .b0 {
        position: absolute;
        top: 7vh;
        left: 0.2vw;
    }

    .itens.b .b1{
        position: absolute;
        top: 12vh;
        left: 0.2vw;
    }

    .itens.b .b2{
        position: absolute;
        top: 17vh;
        left: 0.2vw;
    }

    .itens.b .b3{
        position: absolute;
        top: 22vh;
        left: 0.2vw;
        max-width: 230px;
    }

    .itens.b .b4{
        position: absolute;
        top: 33vh;
        left: 0.2vw;
    }

    .itens.v .v0 {
        position: absolute;
        top: 32vh;
        left: 0.2vw;
    }

    .itens.v .v1{
        position: absolute;
        top: 27vh;
        left: 0.2vw;
    }

    .itens.v .v2{
        position: absolute;
        top: 22vh;
        left: 0.2vw;
    }

    .itens.v .v3{
        position: absolute;
        top: 14.5vh;
        left: 0.2vw;
    }

    .itens.v .v4{
        position: absolute;
        top: 9.5vh;
        left: 0.2vw;
    }

    .itens.l .l0{
        position: absolute;
        top: 7vh;
        right: 0.2vw;
    }

    .itens.l .l1{
        position: absolute;
        top: 12vh;
        right: 0.2vw;
    }

    .itens.l .l2{
        position: absolute;
        top: 17vh;
        right: 0.2vw;
    }

    .itens.l .l3{
        position: absolute;
        top: 22vh;
        right: 0.2vw;
    }

    .itens.l .l4{
        position: absolute;
        top: 30vh;
        right: 0.2vw;
    }

    .itens.a .a0 {
        position: absolute;
        top: 32vh;
        right: 0.2vw;
    }

    .itens.a .a1{
        position: absolute;
        top: 27vh;
        right: 0.2vw;
    }

    .itens.a .a2{
        position: absolute;
        top: 22vh;
        right: 0.2vw;
    }

    .itens.a .a3{
        position: absolute;
        top: 14vh;
        right: 0.2vw;
    }

    .itens.a .a4{
        position: absolute;
        top: 9vh;
        right: 0.2vw;
    }

    .itens.m .m0 {
        position: absolute;
        top: 5vh;
    }

    .itens.m{
        font-size: 13pt;
    }

    .itens.m .m1{
        position: absolute;
        top: 10vh;
    }

    .itens.m .m2{
        position: absolute;
        top: 15vh;
    }

    .itens.m .m3{
        position: absolute;
        top: 23vh;
        min-width: 300px;
    }

    .itens.m .m4{
        position: absolute;
        top: 31vh;
    }



    .imagem.b{
        top:0;
        right: 0;
    }

    .imagem.m{
        top: 7vh;
        right: 0;
    }

    .imagem.m img{
        width: 100px;
    }

    .imagem.l{
        top:0;
        left:0
    }

    .imagem.v{
        top: 25vh;
        right: 0;
    }

    .imagem.a{
        top: 25vh;
        left: 0;
    }



    .titulo.b{
        top:0;
        left: 0;
    }

    .titulo.v{
        top: 36vh;
        left: 0;
    }

    .titulo.l{
        top:0;
        right: 0;
    }

    .titulo.a{
        top: 36vh;
        right: 0;
    }

    .titulo.m{
        top: -1.5vh;
        width: 100%;
    }

    .final{
        font-size: 13pt;
    }

    .caixa.b{
        position: absolute;
        top:0;
        left: 0;
        width: 49%;
        height: 44vh;
        background-color: #c3c4c5;
        background-position: bottom left;
        background-size: contain;
        background-repeat: no-repeat;
        border-top-left-radius: 20px;
        min-height: 360px !important;
    }

    .caixa.l{
        position: absolute;
        top:0;
        left: 49%;
        width: 49%;
        height: 44vh;
        background-color: #d17c19;
        border-top-right-radius: 20px;
        min-height: 360px !important;
    }

    .caixa.v{
        position: absolute;
        top: 44vh;
        left: 0;
        width: 49%;
        height: 44vh;
        background-color: #76a735;
        border-bottom-left-radius: 20px;
        min-height: 360px !important;
    }

    .caixa.a{
        position: absolute;
        top: 44vh;
        left: 49%;
        width: 49%;
        height: 44vh;
        background-color: #17639f;
        border-bottom-right-radius: 20px;
        min-height: 360px !important;
    }

    .caixa.m{
        position: absolute;
        top:calc(26vh - 10px);
        left: calc(29% - 10px);
        width: 40%;
        height: 38vh;
        background-color: #60564b;
        border: 10px solid white;
        border-radius: 20px;
    }


    .itens.b .errado{
        position: absolute;
        top:33vh;
        left: 0.2vw;
    }

    .itens.l .errado{
        position: absolute;
        top:33vh;
        right: 0.2vw;
    }

    .itens.v .errado{
        position: absolute;
        top: 0;
        left: 0.2vw;
    }

    .itens.a .errado{
        position: absolute;
        top: 0;
        right: 0.2vw;
    }

    .itens.m .errado{
        position: absolute;
        top: 30.5vh;
        width: max-content;
        max-width: 280px;
    }

}

@media screen and (max-width: 1179px) {

    .itens.m .m3{
        position: absolute;
        top: 23.5vh;
        min-width: 260px;
    }

    .itens.m .m4{
        position: absolute;
        top: 34vh;
        min-width: 260px;
    }
}